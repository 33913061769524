import React from 'react'
import { motion } from 'framer-motion'

const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
}

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5
}

const pageStyle = {
  position: 'relative',
  width: '100%'
}

export const Wrapper = React.forwardRef(({ className = '', children }, ref) => {
  return (
    <motion.div
      style={pageStyle}
      initial='initial'
      animate='in'
      exit='out'
      variants={pageVariants}
      transition={pageTransition}
      ref={ref}
      className={className}
    >
      {children}
    </motion.div>
  )
})

export const withTransition = Component => props => (
  <Wrapper className='content'>
    <Component {...props} />
  </Wrapper>
)
