import React from 'react'
import createPresets from './presets'

export default ({ setDates, setFocus, t, locale }) => props => {
  const presets = createPresets(locale)
  const createHandler = ({ startDate, endDate }) => () => {
    setDates({ startDate, endDate })
    setFocus(null)
  }

  return (
    <div className='pt-2'>
      <ul className='absolute md:relative mb-24 md:mb-0 bottom-0 flex justify-center text-xs flex-wrap md:flex-no-wrap'>
        {presets.map(preset => (
          <li
            key={`preset-${preset.id}`}
            onClick={createHandler(preset)}
            className='flex p-2 bg-gray-100 border border-gray-300 rounded-full cursor-pointer mr-2'
          >
            {t({ id: preset.id })}
          </li>
        ))}
      </ul>
    </div>
  )
}
