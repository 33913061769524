import m from 'moment'

export const camelCaseToSlug = (str, separator = '_') =>
  str
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
    .toLowerCase()

export const snakeToString = (str, sep = ' ') => str.replace(/_/gi, sep)
export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)
export const snakeToCamel = str =>
  str.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  )

// const colors = ['243, 85, 136', '5, 223, 215', '163, 247, 191', '255, 245, 145']
const defaultDataset = { fill: true }
const blockedDimensions = ['date']

export const parse = (payload, activeMetric, dimensions) => {
  const data = { labels: new Set(), datasets: new Map() }

  for (const row of payload.result.data) {
    data.labels.add(row.date)
    const key = JSON.stringify(
      Object.keys(row).reduce((acc, curr) => {
        if (
          !acc[curr] &&
          !blockedDimensions.includes(curr) &&
          dimensions.find(d => d.active && d.label === curr)
        ) {
          acc[curr] = row[curr]
        }
        return acc
      }, {})
    )

    if (data.datasets.has(key)) {
      const dataset = data.datasets.get(key)
      dataset.data = [...dataset.data, Number(row[activeMetric.label])]
      data.datasets.set(key, dataset)
    } else {
      const dataset = {
        ...defaultDataset,
        label: key,
        data: [Number(row[activeMetric.label])],
        fill: false,
        borderWidth: 4,
        pointBorderWidth: 3
      }
      data.datasets.set(key, dataset)
    }
  }

  data.labels = Array.from(data.labels).map(d => m(d, 'YYYY-MM-DD'))
  data.datasets = Array.from(data.datasets.values())

  // let maxValue = 0
  // let maxIndex = 0
  // for (let i = 0; i < data.datasets.length; i++) {
  //   const ds = data.datasets[i]
  //   const max = Math.max.call(...ds.data)
  //   if (max > maxValue) {
  //     maxValue = max
  //     maxIndex = i
  //   }
  // }

  // data.datasets[maxIndex].fill = 'origin'
  // data.datasets[maxIndex].backgroundColor = 'rgba(0, 0, 0, .5)'

  return data
}
