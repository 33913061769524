import React from 'react'

import cn from 'classnames'
// import Icon from '../Icon'

const noop = () => {}

function Pill ({
  active,
  id,
  children,
  onClick = noop,
  loading,
  className = '',
  style = {}
}) {
  return (
    <div style={style} className={cn('pill', { active }, className)} onClick={onClick}>
      {children}
    </div>
  )
}

export default Pill
