import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import cn from 'classnames'
import { createPortal } from 'react-dom'

const CLOSE_AFTER = 3500

function Noty ({ children, ...props }) {
  const [hide, setHide] = useState(false)

  useEffect(() => { setTimeout(() => setHide(true), CLOSE_AFTER) }, [])

  if (hide) {
    return null
  }

  return createPortal(
    <motion.div
      animate={{ opacity: 1, y: 25 }}
      transition={{ ease: 'easeOut', duration: 1 }}
      className={cn('noty', props.type || 'default')}
    >
      {children}
    </motion.div>,
    document.getElementById('noty')
  )
}

export default Noty
