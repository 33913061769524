import React from 'react'

import { useLayout } from './context'
import Link from '../Link'
import Area from './Area'
// import Icon from '../Icon'
import Actions from './Actions'

export default () => {
  const { refs } = useLayout()

  return (
    <nav className='content' ref={refs.nav}>
      <div className='brand'>
        <Link to='/'>
          <img alt='tappx logo' src='/images/logo.png' />
        </Link>
        <span className='sep' />
        <Area />
      </div>
      <Actions />
    </nav>
  )
}
