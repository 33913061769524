import React from 'react'
import cn from 'classnames'

import { useReportState, useReportDispatch } from '../Report/context'
import { snakeToString, capitalize } from '../../lib/helpers'

function Table () {
  const { data, sort, filters, metrics, showTotals } = useReportState()  
  const { updateSort } = useReportDispatch()

  if (!data || !data.length) {
    return null
  }  

  // const bundleFilter = filters.find(f => f.id === 'bundle').value
  // const showBundle = bundleFilter && bundleFilter.value && bundleFilter.value.length > 1
  const filterRow = field => field !== '__typename'
  // const metricsNames = metrics.map(m => m.id)
  const headers = Object.keys(data[0]).filter(filterRow)

  return (
    <table className='table table-auto text-sm'>
      <thead className='sticky top-0'>
        <tr>
          {headers
            .filter(field => field !== '__typename')
            .map(field => { 
              const isSortActive = sort.find(s => Object.keys(s)[0] === field)
              return (
                <th key={`th-${field}`}>
                  <div className="flex justify-center">
                    {capitalize(snakeToString(field))}
                    <div className='ml-3 flex flex-col'>
                      <i
                        onClick={() => updateSort(field, 'asc')}
                        className={cn(isSortActive && isSortActive[field] === 'asc' ? 'text-gray-600' : 'text-gray-400', 'fas fa-angle-up cursor-pointer')} style={{ marginBottom: '-2px' }}
                      />
                      <i
                        onClick={() => updateSort(field, 'desc')}
                        className={cn(isSortActive && isSortActive[field] === 'desc' ? 'text-gray-600' : 'text-gray-400','fas fa-angle-down cursor-pointer')} style={{ marginTop: '-2px' }}
                      />
                    </div>
                  </div>
                </th>
              )
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => {
          const values = Object.keys(row).filter(filterRow)

          return (
            <tr key={`tr-${i}`} className='bg-white md:even:bg-gray-100'>
              {values
                .filter(v => v !== '__typename')
                .map((v, i) => {                
                  const n = Number(row[v])
                  const isNumeric = !isNaN(n)
                  const value = !isNumeric ? row[v] : n < 1 && n > 0 ? n.toFixed(2) : n

                  return (
                    <td key={`td-${i}`} className='col'>
                      <span className='md:hidden font-bold md:font-normal'>
                        {capitalize(snakeToString(v))}
                      </span>
                      <span>
                        {value}
                      </span>
                    </td>
                  )
                })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Table
