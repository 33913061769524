import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { useGlobal } from './globalState'

const isValidToken = token => !!token

export default function (opts = {}) {
  return function (Component) {
    return function (props) {
      const history = useHistory()
      const { lang } = useParams()
      const { locale } = useIntl()
      const { state } = useGlobal()
      const isLogin = history.location.pathname === `/${locale}/login`

      // When URL lang doesn't match react-intl locale
      useEffect(() => {
        if (locale && lang && locale !== lang) {
          history.replace(history.location.pathname.replace(`/${lang}`, `/${locale}`))
        }

        if (history.location.pathname.charAt(history.location.pathname.length - 1) === '/') {
          history.replace(history.location.pathname.slice(0, -1))
        }
      })

      // Trying to access a restricted page without being logged in
      if (!isLogin && (!state.user || !isValidToken(state.user.token))) {
        return (window.location.href = `/${locale}/login`)
      }

      return (
        <Component {...props} />
      )
    }
  }
}
