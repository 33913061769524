import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
// import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
// import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
// import { ApolloLink } from 'apollo-link'

import { IntlProviderWrapper as IntlProvider } from './locales'
import { StateProvider, LOCALSTORAGE_KEY } from './globalState'
import Login from './pages/login'
import Admin from './pages/admin'
import NoLang from './pages/nolang'

// const client = new ApolloClient({
//   uri: process.env.REACT_APP_GRAPHQL,
//   credentials: 'same-origin',
//   request: ({ setContext }) => {
//     const state = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_KEY))
//     setContext({
//       headers: {
//         authorization: state && state.user && state.user.token ? state.user.token : ''
//       }
//     })
//   }
// })

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL,
  credentials: 'same-origin'
})

const authLink = setContext((_, { headers }) => {
  const state = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_KEY))
  return {
    headers: {
      ...headers,
      authorization: state && state.user && state.user.token ? state.user.token : ''
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }    
  }
})

function App () {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <StateProvider>
          <IntlProvider>
            <Route
              render={({ location }) => (
                <AnimatePresence exitBeforeEnter initial={false}>
                  <Switch
                    location={location}
                    key={
                      location.pathname.includes('/login')
                        ? 'login'
                        : 'no-login'
                    }
                  >
                    <Route path='/:lang/login' component={Login} />
                    <Route path='/:lang' component={Admin} />
                    <Route path='/' component={NoLang} />
                  </Switch>
                </AnimatePresence>
              )}
            />
          </IntlProvider>
        </StateProvider>
      </ApolloProvider>
    </BrowserRouter>
  )
}

export default App
