import React from 'react'
import { useLocation, Switch } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import Nav from './Nav'
import Sidebar from './Sidebar'
import { useLayout, LayoutProvider } from './context'
import { Wrapper } from '../../pageTransition'

export function Layout ({ children }) {
  const { refs } = useLayout()
  const location = useLocation()

  return (
    <Wrapper>
      <Nav />
      <div className='wrap' ref={refs.wrap}>
        <Sidebar ref={refs.sidebar} />
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={location.pathname}>
            {children}
          </Switch>
        </AnimatePresence>
      </div>
    </Wrapper>
  )
}

export const withLayout = Component => props => {
  return (
    <LayoutProvider>
      <Component {...props} />
    </LayoutProvider>
  )
}

export default Layout
