import React from 'react'
import { useRouteMatch, Route } from 'react-router-dom'

import Dash from './dash'
import Report from './report'
import Settings from './settings'
import { Layout, withLayout } from '../../components/Layout'
import withMiddleware from '../../withMiddleware'

function Admin ({ children }) {
  const { path } = useRouteMatch()

  return (
    <Layout>
      <Route path={path} exact component={Dash} />
      <Route path={`${path}/report/:report`} component={Report} />
      <Route path={`${path}/settings`} component={Settings} />
    </Layout>
  )
}

export default withMiddleware()(withLayout(Admin))
