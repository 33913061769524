import React from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

export default ({ to, children, ...props }) => {
  const { locale } = useIntl()

  return (
    <Link {...props} to={`/${locale}${to}`}>
      {children}
    </Link>
  )
}
