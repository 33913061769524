import React from 'react'
import cn from 'classnames'
import { useIntl } from 'react-intl'

import Dropdown from '../../Dropdown'
import { useGlobal } from '../../../globalState'

const AREAS = {
  admin: {
    label: 'Admin',
    icon: 'fa-user-circle'
  },
  supply: {
    label: 'Supply',
    icon: 'fa-parachute-box'
  },
  demand: {
    label: 'Demand',
    icon: 'fa-hand-holding'
  },
  performance: {
    label: 'Performance',
    icon: 'fa-tachometer-alt'
  },
  finance: {
    label: 'Finance',
    icon: 'fa-piggy-bank'
  },
  tech: {
    label: 'Tech',
    icon: 'fa-laptop'
  }
}

function Area () {
  const { state: { user, area }, actions: { setArea } } = useGlobal()
  const { formatMessage: t } = useIntl()

  if (!user) {
    return (
      <div className='area'>
        Loading
      </div>
    )
  }

  const isAdmin = user.role.includes('admin')
  const firstArea = user.role[0]

  if (!isAdmin && user.role.length === 1) {
    return (
      <div className='area'>
        <i className={cn('fas', AREAS[firstArea].icon)} />
        {t({ id: firstArea })}
      </div>
    )
  }

  return (
    <Dropdown className='role'>
      <Dropdown.Trigger>
        <div className='area'>
          <i className={cn('fas', AREAS[area].icon)} />
          {t({ id: area })}
        </div>
      </Dropdown.Trigger>
      <Dropdown.Menu>
        {Object.keys(AREAS).map(
          (role, i) =>
            role !== area && (
              <Dropdown.Item key={`role-${AREAS[role].label}`}>
                <div className='area xs' onClick={() => setArea(role)}>
                  <i className={cn('fas', AREAS[role].icon)} />
                  {t({ id: role })}
                </div>
              </Dropdown.Item>
            )
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default Area
