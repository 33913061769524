import m from 'moment'

export default locale => {
  m.locale(locale.split('-')[0])

  return [
    {
      startDate: m().subtract(1, 'days'),
      endDate: m(),
      id: 'last.two.days'
    },
    {
      startDate: m(),
      endDate: m(),
      id: 'today'
    },
    {
      startDate: m().subtract(1, 'day'),
      endDate: m().subtract(1, 'day'),
      id: 'yesterday'
    },
    {
      startDate: m().subtract(1, 'week').startOf('isoWeek'),
      endDate: m().subtract(1, 'week').endOf('isoWeek'),
      id: 'last.week'
    },
    {
      startDate: m().startOf('month'),
      endDate: m(),
      id: 'current.month'
    },
    {
      startDate: m().subtract(1, 'month').startOf('month'),
      endDate: m().subtract(1, 'month').endOf('month'),
      id: 'last.month'
    }
  ]
}
