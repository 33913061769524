import React from 'react'
import { useIntl } from 'react-intl'
import { motion, AnimatePresence } from 'framer-motion'

const createMarkup = __html => ({ __html })

const variants = {
  visible: {
    opacity: 1,
    y: 0
  },
  hidden: {
    opacity: 0,
    y: -50
  }
}

const transition = {
  duration: 1
}

function Welcome ({ mail, name, pic, text }) {
  const { formatMessage } = useIntl()

  return (
    <div className='welcome-wrapper'>
      <AnimatePresence>
        {mail && (
          <motion.div
            className='welcome'
            key={mail}
            variants={variants}
            initial='hidden'
            exit='hidden'
            animate='visible'
            transition={transition}
          >
            <div className='welcome-pic'>
              <img src={pic} alt={name} />
            </div>
            <div className='welcome-text'>
              <h1>{`${formatMessage({ id: 'hello' })}, ${name}`}!</h1>
              <p dangerouslySetInnerHTML={createMarkup(text || formatMessage({ id: 'default.login.welcome' }))} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Welcome
