import React from 'react'
import { useIntl } from 'react-intl'

import { useReportState, useReportDispatch } from './context'
import PillPicker from '../PillPicker'
import Button from '../Button'

// const blocked = ['date', 'bundle']
const blocked = []

function Dimensions () {
  const { formatMessage: t } = useIntl()
  const { dimensions } = useReportState()
  const actions = useReportDispatch()

  if (!dimensions) {
    return (
      <Button
        rounded='rounded-lg'
        className='font-normal bg-gray-100 border border-gray-300 text-gray-500'
      >
        <i className='mr-2 fas fa-layer-group' />
        {t({ id: 'dimensions' })}
      </Button>
    )
  }

  const activeDimensions = dimensions.filter(d => d.active && !blocked.includes(d.id))

  const icon = (
    <>
      <i className='mr-2 fas fa-layer-group' />
      {activeDimensions && activeDimensions.length ? <div className="num">{activeDimensions.length}</div> : null } 
    </>
  )

  return (
    <div className='report-dimensions'>
      <PillPicker
        name={t({ id: 'dimensions' })}
        icon={icon}
        pills={dimensions.filter(({ id }) => !blocked.includes(id))}
        update={actions.dimensions.update}
      />
    </div>
  )
}

export default Dimensions
