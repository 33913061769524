import React from 'react'
import cn from 'classnames'
import Icon from '../Icon'

function Button ({
  loading,
  children,
  rounded = 'rounded-full',
  className = '',
  ...props
}) {
  return (
    <button
      className={cn('btn', className, rounded, { disabled: props.disabled })}
      {...props}
    >
      {loading ? <Icon /> : null}
      {children}
    </button>
  )
}

export default Button
