import React, { createContext, useReducer, useContext } from 'react'
import { capitalize, snakeToString, parse } from '../../../lib/helpers'

const ReportContextState = createContext()
const ReportContextDispatch = createContext()

const ACTIONS = {
  SET_DATA: 'SET_DATA',
  SET_DATES: 'SET_DATES',
  SET_FOCUS: 'SET_FOCUS',
  SET_CONFIG: 'SET_CONFIG',
  SET_DIMENSIONS: 'SET_DIMENSIONS',
  SET_METRICS: 'SET_METRICS',
  TOGGLE_METRIC: 'TOGGLE_METRIC',
  UPDATE_FILTER: 'UPDATE_FILTER',
  TOGGLE_OPEN_FILTER: 'TOGGLE_OPEN_FILTER',
  // TOGGLE_LOADING: 'TOGGLE_LOADING',
  UPDATE_DIMENSIONS: 'UPDATE_DIMENSIONS',
  TOGGLE_TOTALS: 'TOGGLE_TOTALS',
  CHANGE_PAGE: 'CHANGE_PAGE',
  TOGGLE_SKIP: 'TOGGLE_SKIP',
  UPDATE_SORT: 'UPDATE_SORT'
}

const initialState = {
  dates: {
    startDate: null,
    endDate: null,
    focusedInput: null
  },
  filters: null,
  dimensions: null,
  metrics: null,
  sort: [],
  loading: false,
  data: null,
  chartRef: null,
  showTotals: false,
  page: 1,
  pages: null,
  skip: true
}

const toLabel = str => capitalize(snakeToString(str))

export const parseDimensions = dimensions => Object.keys(dimensions).map(d => ({ label: toLabel(d), id: d, active: d === 'date' }))
export const parseMetrics = metrics => Object.keys(metrics).map((m, i) => ({ id: m, label: toLabel(m), active: false }))
export const parseFilters = filters => Object.keys(filters).reduce((acc, label) => {
  const { type, options } = filters[label]
  acc.push({
    id: label,
    label: toLabel(label),
    type,
    options,
    value: null,
    open: false,
    active: false
  })
  return acc
}, [])

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_SORT: {
      const isBeingUsed = state.sort.find(s => Object.keys(s)[0] === action.key)
      
      let sort = state.sort
      if (isBeingUsed && isBeingUsed[action.key] === action.value) {
        sort = state.sort.filter(s => Object.keys(s)[0] !== action.key)
      } else if (isBeingUsed && isBeingUsed[action.key] !== action.value) {
        sort = [{ [action.key]: action.value }, ...state.sort.filter(s => Object.keys(s)[0] !== action.key)]
      } else if (!isBeingUsed) {
        sort = [{ [action.key]: action.value }, ...state.sort]
      }

      return {
        ...state,
        sort,
        data: null,
        skip: false,
        loading: true,
        page: 1
      }
    }
    case ACTIONS.TOGGLE_SKIP: {
      return { ...state, skip: !state.skip }
    }
    case ACTIONS.CHANGE_PAGE: {
      return { ...state, page: action.page, data: null, loading: true, skip: false }
    }
    case ACTIONS.TOGGLE_TOTALS: {
      return { ...state, showTotals: !state.showTotals }
    }
    case ACTIONS.SET_CONFIG: {      
      return { ...state, ...action.config }
    }
    case ACTIONS.SET_DATA: {
      // action.value.metrics = action.value.metrics ? parseMetrics(action.value.metrics) : state.metrics
      // action.value.filters = action.value.filters ? parseFilters(action.value.filters) : state.filters
      // action.value.dimensions = action.value.dimensions ? parseDimensions(action.value.dimensions) : state.dimensions
      // const chartData = parse(
      //   action.value.data,
      //   action.value.metrics.find(m => m.active),
      //   action.value.dimensions
      // )

      return {
        ...state,
        ...action.value,
        loading: false
        // chartData,
      }
    }
    case ACTIONS.SET_DATES:
      return { ...state, dates: { ...state.dates, ...action.value } }
    case ACTIONS.TOGGLE_METRIC: {
      return {
        ...state,
        metrics: state.metrics.map(m => {
          if (m.id === action.value) {
            return {...m, active: !m.active}
          }
          return {...m}
        })
      }
    }
    case ACTIONS.SET_METRICS:
      return { ...state, metrics: parseMetrics(action.value) }
    case ACTIONS.SET_FOCUS:
      return { ...state, dates: { ...state.dates, focusedInput: action.value } }
    case ACTIONS.SET_DIMENSIONS:
      return { ...state, dimensions: parseDimensions(action.value) }
    case ACTIONS.UPDATE_DIMENSIONS:
      return { ...state, dimensions: state.dimensions.map(d => ({ ...d, active: d.label === action.value ? !d.active : d.active })) }
    case ACTIONS.UPDATE_FILTER: {
      const filters = state.filters.map(f => {
        f.value = f.id === action.value.key ? action.value.value : f.value
        f.active = f.value && f.value !== '' ? true : false
        return f
      })
      return { ...state, filters }
    }
    case ACTIONS.TOGGLE_OPEN_FILTER: {
      const filters = state.filters.map(f => {
        f.open = f.label === action.value ? !f.open : false
        return f
      })
      return { ...state, filters }
    }
    case ACTIONS.TOGGLE_LOADING:
      return { ...state, loading: !state.loading }
    default:
      return { ...state }
  }
}

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ReportContextState.Provider value={state}>
      <ReportContextDispatch.Provider value={dispatch}>
        {children}
      </ReportContextDispatch.Provider>
    </ReportContextState.Provider>    
  )
}

export const useReportDispatch = () => {
  const dispatch = useContext(ReportContextDispatch)

  const actions = {
    toggleSkip: () => dispatch({ type: ACTIONS.TOGGLE_SKIP }),
    toggleTotals: () => dispatch({ type: ACTIONS.TOGGLE_TOTALS }),
    setConfig: config => dispatch({ type: ACTIONS.SET_CONFIG, config }),
    changePage: page => dispatch({ type: ACTIONS.CHANGE_PAGE, page }),
    updateSort: (key, value) => dispatch({ type: ACTIONS.UPDATE_SORT, key, value }),
    data: {
      fill: value => dispatch({ type: ACTIONS.SET_DATA, value })
    },
    toggleLoading: () => dispatch({ type: ACTIONS.TOGGLE_LOADING }),
    dates: {
      setDates: value => dispatch({ type: ACTIONS.SET_DATES, value }),
      setFocus: value => dispatch({ type: ACTIONS.SET_FOCUS, value })
    },
    filters: {
      // set: value => dispatch({ type: ACTIONS.SET_FILTERS, value }),
      update: (key, value) => dispatch({ type: ACTIONS.UPDATE_FILTER, value: { key, value } }),
      toggle: value => dispatch({ type: ACTIONS.TOGGLE_OPEN_FILTER, value })
    },
    dimensions: {
      set: value => dispatch({ type: ACTIONS.SET_DIMENSIONS, value }),
      update: value => dispatch({ type: ACTIONS.UPDATE_DIMENSIONS, value })
    },
    metrics: {
      set: value => dispatch({ type: ACTIONS.SET_METRICS, value }),
      update: value => dispatch({ type: ACTIONS.TOGGLE_METRIC, value })
    }
  }

  // useEffect(() => {
  //   load()
  // }, [])

  // useEffect(() => {
  //   if (data && !loading && !error) {
  //     console.log('tinc data', data);
  //     // dispatch({ type: '' })
  //   }
  // }, [data, loading, error])

  return { ...actions }
}

export const useReportState = () => useContext(ReportContextState)
  
