export default [
  {
    id: 'deals',
    slug: 'deals',
    icon: 'far fa-handshake'
  },
  {
    id: 'networks',
    slug: 'networks',
    icon: 'fas fa-network-wired'
  },
  {
    id: 'floors',
    slug: 'floors',
    icon: 'far fa-minus-square'
  },
  {
    id: 'register.network',
    slug: 'register-network',
    isButton: true
  }
]
