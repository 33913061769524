export default [
  {
    slug: 'invoices',
    icon: 'fas fa-file-invoice-dollar',
    id: 'invoices'
  },
  {
    slug: 'create-invoice',
    id: 'create.invoice',
    isButton: true
  },
  {
    slug: 'create-credit-note',
    id: 'create.credit.note',
    isButton: true
  }
]
