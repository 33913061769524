import React from 'react'
import cn from 'classnames'
import { useIntl } from 'react-intl'
import m from 'moment'

import renderPresets from './renderPresets'
import Datepicker from '../../Datepicker'
import { useReportState, useReportDispatch } from '../context'
import { useLayout } from '../../Layout/context'

function ReportsDatepicker (props) {
  const { locale, formatMessage: t } = useIntl()
  const { viewport } = useLayout()
  const { dates } = useReportState()
  const { dates: { setDates, setFocus }} = useReportDispatch()

  // React.useEffect(() => {
  //   if (viewport.isMobile && dates.focusedInput) {
  //     document.body.classList.add('overflow-y-hidden', 'h-screen')
  //   }

  //   if (viewport.isMobile && !dates.focusedInput) {
  //     document.body.classList.remove('overflow-y-hidden', 'h-screen')
  //   }
  // }, [viewport.isMobile, dates.focusedInput])

  // if (!dates.startDate && !dates.endDate && !dates.focusedInput) {
  //   return (
  //     <div className='reports-dates-wrapper' onClick={() => setFocus('startDate')}>
  //       <i className='far fa-calendar-alt mr-2' />
  //       Choose dates
  //     </div>
  //   )
  // }

  m.locale(locale.split('-')[0])

  return (
    <div className={cn('report-dates', dates.focusedInput, { 'rounded-b-none': !!dates.focusedInput, 'border-b-0': !!dates.focusedInput })}>
      <i
        className='far fa-calendar-alt mr-1'
        onClick={e => !dates.focusedInput && setFocus('startDate')}
      />
      <Datepicker
        {...props}
        {...dates}
        renderCalendarInfo={viewport.isMobile ? null : renderPresets({ setFocus, setDates, t, locale })}
        startDateId='reportsStartDate'
        endDateId='reportsEndDate'
        onDatesChange={setDates}
        onFocusChange={setFocus}
        showClearDates
        noBorder
        numberOfMonths={2}
        minimumNights={0}
        withFullScreenPortal={viewport.isMobile}
        withPortal={false}
        orientation={viewport.isMobile ? 'vertical' : 'horizontal'}
        disableScroll={viewport.isMobile}
        readOnly={viewport.isMobile}
        startDatePlaceholderText={t({ id: 'start.date' })}
        endDatePlaceholderText={t({ id: 'end.date' })}
      />
    </div>
  )
}

export default ReportsDatepicker
