import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import 'moment/locale/es'
import 'moment/locale/ca'
import 'moment/locale/en-gb'
import 'moment/locale/zh-cn'
import 'react-toggle/style.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './styles/global.css'

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
