import React from 'react'
import cn from 'classnames'

function Checkbox ({ children, className, ...props }) {
  return (
    <div className={cn('checkbox', className)}>
      <input type='checkbox' {...props} />
      <label htmlFor={props.id}>
        {children}
      </label>
    </div>
  )
}

export default Checkbox
