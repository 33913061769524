import React from 'react'
import Report from '../../components/Report'
import { Provider as ReportProvider } from '../../components/Report/context'
import { withTransition } from './transitions'

const Reports = () => (
  <ReportProvider>
    <Report />
  </ReportProvider>
)

export default withTransition(Reports)
