import React from 'react'
import Pill from '../Pill'

const Loading = () => {
  return (
    <>
      {new Array(4).fill(true).map((a, i) => (
        <Pill
          key={`pill-${i}`}
          id={`loading-pill-${i}`}
          active={false}
          className='w-16 text-gray-100'
        >
          Loading
        </Pill>
      ))}
    </>
  )
}

export default Loading;