import finance from './finance'
import demand from './demand'
import performance from './performance'
import tech from './tech'

const menus = {
  admin: [],
  supply: [],
  demand: [...demand],
  performance: [...performance],
  tech: [...tech],
  finance: [...finance]
}

export default menus
