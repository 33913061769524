import React, { useEffect, createContext, useReducer, useContext } from 'react'
import Cookies from 'js-cookie'
import { whichLang, COOKIE_KEY } from '../locales'

export const LOCALSTORAGE_KEY = '__MYADMIN_STATE__'
const defaultState = {
  locale: null,
  theme: 'light',
  user: null,
  area: null
}
const storageState = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_KEY))
const initialState = storageState || defaultState
const Context = createContext()

const processAction = state => {
  window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(state))
  return state
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AREA':
      return processAction({
        ...state,
        area: action.value
      })
    case 'SET_LANG':
      Cookies.set(COOKIE_KEY, action.value)
      return processAction({
        ...state,
        locale: action.value
      })
    case 'TOGGLE_THEME':
      return processAction({
        ...state,
        theme: state.theme === 'light' ? 'dark' : 'light'
      })
    case 'LOGIN_LS':
      return processAction({ ...state, user: action.value })
    case 'LOGIN': {
      const { token, user } = action.value
      return processAction({ ...state, user: { token, ...user }, area: user.role[0] })
    }
    case 'LOGOUT':
      return processAction({ ...state, user: null })
    default:
      return { ...state }
  }
}

export const StateProvider = ({ children }) => {
  const locale = whichLang()

  return (
    <Context.Provider value={useReducer(reducer, { ...initialState, locale })}>
      {children}
    </Context.Provider>
  )
}
export const useGlobal = () => {
  const [state, dispatch] = useContext(Context)

  const actions = {
    toggleTheme: theme => dispatch({ type: 'TOGGLE_THEME' }),
    login: user => dispatch({ type: 'LOGIN', value: user }),
    logout: (cb) => dispatch({ type: 'LOGOUT' }),
    changeLang: value => dispatch({ type: 'SET_LANG', value }),
    setArea: value => dispatch({ type: 'SET_AREA', value })
  }

  useEffect(() => {
    const storageState = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_KEY))
    if (!storageState && state.user) {
      window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(state))
    } else if (!state.user && storageState && storageState.user) {
      dispatch({ type: 'LOGIN_LS', value: storageState.user })
    }
  })

  return {
    state,
    dispatch,
    actions
  }
}
