import React from 'react'
import cn from 'classnames'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { useLayout } from '../context'
import { useGlobal } from '../../../globalState'
import Dropdown from '../../Dropdown'
// import Notifications from '../Notifications'
import Link from '../../Link'
import mails from '../../../pages/login/mails.json'
import user from '../../../images/user.png'

function Actions () {
  const history = useHistory()
  const { locale, formatMessage: t } = useIntl()
  const { actionsToggled } = useLayout()
  const { state, actions } = useGlobal()
  const handleChangeLang = e => actions.changeLang(e.target.getAttribute('data-lang'))
  const handleLogout = () => history.push(`/${locale}/login`, { logout: true })
  const userData = mails[state.user.mail]

  return (
    <div className={cn('menu', { active: actionsToggled })}>
      <ul>
        {/* <li>
          <Notifications />
        </li> */}
        <li>
          <Dropdown className='locale'>
            <Dropdown.Trigger>
              <span>
                <i className='fas fa-globe-americas' />
              </span>
            </Dropdown.Trigger>
            <Dropdown.Menu>
              <Dropdown.Item>
                <span data-lang='en' onClick={handleChangeLang} className='flag'>
                  <img src='/flags/us.svg' alt='USA Flag' />
                  English
                </span>
              </Dropdown.Item>
              <Dropdown.Item>
                <span data-lang='zh' onClick={handleChangeLang} className='flag'>
                  <img src='/flags/zh.svg' alt='China Flag' />
                  汉语
                </span>
              </Dropdown.Item>
              <Dropdown.Item>
                <span data-lang='es' onClick={handleChangeLang} className='flag'>
                  <img src='/flags/es.svg' alt='Spain Flag' />
                  Español
                </span>
              </Dropdown.Item>
              <Dropdown.Item>
                <span data-lang='ca' onClick={handleChangeLang} className='flag'>
                  <img src='/flags/ca.svg' alt='Catalan Flag' />
                  Català
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
        <li className='is-hidden'>
          <a href="https://forms.gle/xaQ574fDzyKx9Ebj7" target='_BLANK'>
            <i className='fas fa-bug mr-2' />
            <span className='text-xs'>{t({ id: 'bug' })}!</span>
          </a>
        </li>
        <li>
          <Dropdown className='profile' width='12rem'>
            <Dropdown.Trigger>
              <div className='avatar'>
                <img alt='profile pic' src={userData.pic || user} />
              </div>
            </Dropdown.Trigger>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link to='/settings'>
                  <i className='fas fa-cog' />
                  <span>{t({ id: 'settings' })}</span>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <i className='far fa-moon' />
                <span>{t({ id: 'dark.theme' })}</span>
              </Dropdown.Item>
              <Dropdown.Item>
                <div onClick={handleLogout}>
                  <i className='fas fa-sign-out-alt' />
                  <span>{t({ id: 'logout' })}</span>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    </div>
  )
}

export default Actions
