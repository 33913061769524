import React from 'react'
import { useIntl } from 'react-intl'

import { useReportState, useReportDispatch } from './context'
// import PillPicker from '../PillPicker'
// import Button from '../Button'
import Dropdown from '../../components/Dropdown'
import Checkbox from '../../components/Form/Checkbox'

// const blocked = []

// function Metrics () {
//   const { metrics } = useReportState()
//   const actions = useReportDispatch()

//   if (!metrics) {
//     return (
//       <Button
//         rounded='rounded-lg'
//         className='ml-4 font-normal bg-gray-100 border border-gray-300 text-gray-500'
//       >
//         <i className="mx-2 fas fa-search-plus"></i>
//         Metrics
//       </Button>
//     )
//   }

//   return (
//     <PillPicker
//       name='Metrics'
//       icon={<i className="mx-2 fas fa-search-plus"></i>}
//       pills={metrics.filter(({ id }) => !blocked.includes(id))}
//       update={actions.metrics.update}
//     />
//   )
// }

function Metrics () {
  const { formatMessage: t } = useIntl()
  const { metrics } = useReportState()
  const actions = useReportDispatch()
  const activeMetrics = metrics ? metrics.filter(m => m.active) : []

  return (
    <Dropdown className='metrics mr-4' width='12rem'>
      <Dropdown.Trigger>
        <div className='relative cursor-pointer h-full bg-gray-100 flex items-center rounded-lg p-2 border border-gray-300 text-gray-500 text-sm'>
          <i className="mr-2 fas fa-search-plus" />
          {t({ id: 'metrics' })}
         {activeMetrics && activeMetrics.length ? <div className="num">{activeMetrics.length}</div> : null}
        </div>
      </Dropdown.Trigger>
      <Dropdown.Menu>
        {metrics && metrics.map(({ active, label, id }) => (
          <Dropdown.Item key={`m-${label}`}>
            <Checkbox
              name={id}
              id={id}
              className='rounded'
              checked={active}
              onChange={() => actions.metrics.update(id)}
            >
              <span>
                {label}
              </span>                  
            </Checkbox>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default Metrics
