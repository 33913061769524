import React from 'react'
import cn from 'classnames'

import { useReportState, useReportDispatch } from '../context'

const Pagination = () => {
  const { pages, page } = useReportState()
  const { changePage } = useReportDispatch()
  
  return (
    <div className='flex ml-auto mr-auto justify-center my-4 w-full md:max-w-lg overflow-hidden md:overflow-x-scroll'>
      <div className="w-full flex">
        {new Array(pages).fill(true).map((n, i) => (
          <div
            onClick={page !== (i + 1) ? () => changePage(i + 1) : null}
            key={`page-${i}`}
            className={cn(page === (i + 1) ? 'bg-gray-300' : 'cursor-pointer', 'text-gray-600 mr-2 p-2 border border-gray-300 rounded')}
          >
            {i + 1}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Pagination