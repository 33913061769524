import React, { useState } from 'react'
import cn from 'classnames'

import Pill from '../Pill'
import Button from '../Button'
import Loading from './Loading'
import { snakeToString } from '../../lib/helpers'

function PillPicker ({
  update = () => {},
  name = '',
  icon = null, 
  pills = [],
  loading = false,
  opened: openedProp = false
}) {
  const [opened, setOpened] = useState(openedProp)

  return (
    <div className='report-dimensions'>
        {loading
          ? <Loading />
          : pills && pills.length && opened
            ? (
              <div className='flex'>
                {pills.map(
                  ({ label, active }) => (
                      <Pill
                        key={`pill-${label}`}
                        active={active}
                        id={label}
                        onClick={() => update(label)}
                      >
                        {snakeToString(label)}
                      </Pill>
                    )
                )}
              </div>
            )
            : null
        }
      <Button
        rounded='rounded-lg'
        className={cn(opened && 'ml-4', 'font-normal bg-gray-100 border border-gray-300 text-gray-500')}
        onClick={() => pills && setOpened(!opened)}
      >
        {icon}
        {name}
      </Button>
    </div>
  )
}

export default PillPicker
