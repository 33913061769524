import React from 'react'
import { useIntl } from 'react-intl'

import { withTransition, Wrapper as InnerWrapper } from './transitions'
import { useGlobal } from '../../globalState'
import Title from '../../components/Title'

function Dash () {
  const { formatMessage: t } = useIntl()
  const { state } = useGlobal()

  return (
    <InnerWrapper>
      <Title>
        {t({ id: 'dashboard' })}
      </Title>
      {t({ id: 'title' }, { user: state.user.name })}
    </InnerWrapper>
  )
}

export default withTransition(Dash)
