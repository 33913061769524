import React, { useRef } from 'react'
import { motion } from 'framer-motion'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'

import { useGlobal } from '../../../globalState'
import { useLayout } from '../context'
import { capitalize, camelCaseToSlug } from '../../../lib/helpers'
import menus from './menus'

const STATES = {
  'is-visible': 'is-visible',
  'is-toggled': 'is-toggled',
  'is-mobile': 'is-mobile'
}

const variants = {
  [STATES['is-visible']]: {
    width: '300px',
    transition: { when: 'afterChildren' }
  },
  [STATES['is-toggled']]: {
    width: 'auto',
    transition: { when: 'beforeChildren' }
  },
  [STATES['is-mobile']]: {
    width: '100%',
    transition: { when: 'beforeChildren' }
  }
}

function Label ({ children }) {
  const { viewport: { isMobile }, sidebarToggled } = useLayout()

  return (
    <motion.span
      className='ml-2 md:ml-4'
      style={{ display: !isMobile && sidebarToggled ? 'none' : 'inline' }}
    >
      {children}
    </motion.span>
  )
}

function Item ({ isButton, icon, id, slug }) {
  const { viewport: { isMobile }, sidebarToggled } = useLayout()
  const history = useHistory()
  const { formatMessage: t } = useIntl()

  if (isButton) {
    if (!isMobile && sidebarToggled) {
      return (
        <div>
          <i className='fas fa-plus-circle' />
        </div>
      )
    }

    return (
      <div>
        <button className='w-full flex items-center rounded border border-gray-500 p-2'>
          <i className='fas fa-plus-circle' />
          <span className='ml-2' style={{ display: !isMobile && sidebarToggled ? 'none' : 'inline' }}>
            {t({ id })}
          </span>
        </button>
      </div>
    )
  }

  return (
    <div className={cn({ mactive: history.location.pathname.includes(slug) })}>
      <span>
        <i className={cn('far', icon || '')} />
        <Label>{t({ id: id })}</Label>
      </span>
    </div>
  )
}

const Sidebar = React.forwardRef((props, ref) => {
  const { state: { area, user } } = useGlobal()
  const subMenuRef = useRef(null)
  const history = useHistory()
  const { locale, formatMessage: t } = useIntl()
  const { viewport: { isMobile }, sidebarToggled, actions } = useLayout()
  const animate = isMobile ? 'is-mobile' : STATES[sidebarToggled ? 'is-toggled' : 'is-visible']
  const openSubmenu = e => {
    subMenuRef.current.classList.toggle('opened')
  }
  const onClick = e => {
    if (!e.target.isEqualNode(e.currentTarget)) {
      return false
    }
    return !isMobile && actions.toggleSidebar()
  }

  const goTo = e => {
    // e.preventDefault()
    // e.stopPropagation()

    if (!isMobile && sidebarToggled) {
      return actions.toggleSidebar()
    }

    const path = e.currentTarget.getAttribute('data-to')
    isMobile && subMenuRef.current.classList.remove('opened')
    history.push(`/${locale}${path}`)
  }
  
  return (
    <motion.div
      variants={variants}
      initial={false}
      animate={animate}
      className={cn('sidebar', { toggled: sidebarToggled })}
      onClick={onClick}
      ref={ref}
    >
      <ul>
        <li onClick={goTo} data-to=''>
          <div
            className={cn({
              active: history.location.pathname === `/${locale}`
            })}
          >
            <span>
              <i className='far fa-calendar' />
              <Label>{t({ id: 'dashboard' })}</Label>
            </span>
          </div>
        </li>
        {user.acl && user.acl.length && (
          <li className='flex-col'>
            <div
              onClick={openSubmenu}
              className={cn({
                active: history.location.pathname.includes('/report/')
              })}
            >
              <i className='fas fa-th-list' />
              <Label>{t({ id: 'reports' })}</Label>
            </div>
            <motion.ul
              // className={cn({ opened: sidebarToggled })}
              ref={subMenuRef}
              style={{ right: '10px' }}
            >
              {user.acl.filter(dv => dv.includes('dataviewer.')).map((dv, i) => {
                const report = dv.replace('dataviewer.', '')
                const isCurrent = history.location.pathname === `/${locale}/report/${camelCaseToSlug(report, '-')}`                
                const slug = camelCaseToSlug(report, '-')
                const name = capitalize(camelCaseToSlug(report, '-')).replace(
                  /-/gi,
                  ' '
                )

                return (
                  <li
                    key={`report-${i}`}
                    onClick={goTo}
                    data-to={`/report/${slug}`}
                    className={cn({ active: isCurrent })}
                  >
                    <div>{name}</div>
                  </li>
                )
              })}
            </motion.ul>
          </li>
        )}
        {area &&
          menus[area] &&
          menus[area].length
          ? menus[area].map((item, i) => {
            return (
              <li
                key={`sidebar-menu-${item.slug}`}
                onClick={goTo}
                data-to={item.slug}
              >
                <Item {...item} />
              </li>
            )
          }) : null}
      </ul>
    </motion.div>
  )
})

export default Sidebar
