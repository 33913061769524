import React from 'react'
import cn from 'classnames'
import Icons from './icons'

function Icon ({ type, width = '18px', height = '18px', color = '#fff', className = '' }) {
  const Component = Icons[type]

  if (!Component || !type) {
    return null
  }

  return (
    <span className={cn('icon', className)}>
      <Component width={width} height={height} color={color} />
    </span>
  )
}

export default Icon
