import { gql } from 'apollo-boost'

export default gql`
  mutation loginUser($data: UserLoginInput!) {
    loginUser(data: $data) {
      token
      user {
        id
        name
        mail
        acl
        role
      }
    }
  }
`
