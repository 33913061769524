import React from 'react'
import moment from 'moment'
import { isInclusivelyBeforeDay, DateRangePicker } from 'react-dates'

function Datepicker (props) {
  return (
    <DateRangePicker
      {...props}
      customArrowIcon='-'
      isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
    />
  )
}

export default Datepicker
