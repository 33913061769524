import React from 'react'
import { useIntl } from 'react-intl'
import { CSVLink } from 'react-csv'

import Dropdown from '../Dropdown'
import { useReportState } from './context'

function dataForCsv (data) {
  const filename = 'export-myadmin.csv'

  if (!data || !data.length) {
    return { data: [], headers: [], filename}
  }

  const headers = Object.keys(data[0])
  const rows = data.map(row => Object.values(row))
  return { headers, data: rows, filename }
}

function Share () {
  const { formatMessage: t } = useIntl()
  const { data } =  useReportState()
  return (
    <>
      <div>
        <Dropdown className='actions'>
          <Dropdown.Trigger>
            <i className='fas fa-download' />
            <span className='hidden md:inline-block'>{t({ id: 'export' })}</span>
          </Dropdown.Trigger>
          <Dropdown.Menu>
            {/* <Dropdown.Item>
              <i className='far fa-file-pdf' />
              <span className='text-xs'>PDF</span>
            </Dropdown.Item> */}
            <Dropdown.Item>
              <i className='far fa-file-excel' />
              <CSVLink filename='' {...dataForCsv(data)}>
                <span className='text-xs'>Excel</span>
              </CSVLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* <div>
        <Dropdown className='share' width='14rem'>
          <Dropdown.Trigger>
            <i className='fas fa-share-alt' />
            <span className='hidden md:inline-block'>{t({ id: 'share' })}</span>
          </Dropdown.Trigger>
          <Dropdown.Menu>
            <Dropdown.Item>
              <i className='far fa-copy' />
              <input
                className='rounded-lg text-xs p-2 border border-gray-300'
                type='text'
                value='https://myadmin.tappx.com/report/ab583228-5b37-11ea-bc55-0242ac130003'
              />
            </Dropdown.Item>
            <Dropdown.Item>
              <p className='text-xs'>Only logged users can access</p>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div> */}
    </>
  )
}

export default Share
