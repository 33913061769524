import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import Button from '../Button'
import { useReportState, useReportDispatch } from '../Report/context'
import Dropdown from '../Dropdown'

const blockedFilters = ['date']

function Filters () {
  const { formatMessage: t } = useIntl()
  const { filters } = useReportState()
  const activeFilters = filters ? filters.filter(f => !blockedFilters.includes(f.label) && f.active) : null

  if (!filters) {
    return (
      <Button
        rounded='rounded-lg h-full'
        className='font-normal ml-4 bg-gray-100 border border-gray-300 text-gray-500'
      >
        <i className='mx-2 fas fa-filter hidden md:inline-block' />
        {t({ id: 'add.filter' })}
      </Button> 
    )
  }

  return (
    <div className='filters'>
      <Dropdown className='metrics mr-4' width='13rem'>
        <Dropdown.Trigger>
          <Button
            rounded='rounded-lg h-full'
            className='font-normal ml-4 bg-gray-100 border border-gray-300 text-gray-500'
          >
            <i className='mx-2 fas fa-filter hidden md:inline-block' />
            {t({ id: 'add.filter' })}
            {activeFilters && activeFilters.length ? <div className="num">{activeFilters.length}</div> : null}
          </Button>          
        </Dropdown.Trigger>
        <Dropdown.Menu>
          <FilterPanel />
        </Dropdown.Menu>
      </Dropdown> 
    </div>
  )
}

function FilterPanel () {
  const { formatMessage: t } = useIntl()
  const { filters } = useReportState()
  const { filters: { update } } = useReportDispatch()
  const filtersUI = filters.filter(f => !blockedFilters.includes(f.id))
  const [filter, setFilter] = useState(filtersUI[0].id)
  const [value, setValue] = useState(filtersUI[0].value || '')

  function handleSubmit () {
    update(filter, value)
  }

  function handleChange (e) {    
    setFilter(e.target.value)
    setValue(filtersUI.find(f => f.id === e.target.value).value || '')
  }
  
  return (
    <div className='filter-panel'>
      <select
        name=''
        id=''
        className='text-sm rounded border border-gray-300 w-full mb-4'
        onChange={handleChange}
      >
        {filtersUI.map(f => (
          <option className='p-3' key={`filter-opt-${f.label}`} value={f.id}>{f.label}</option>
        ))}
      </select>

      <input
        type='text'
        className='p-3 rounded border border-gray-300 w-full mb-4'
        value={value}
        onChange={e => setValue(e.target.value)}
      />

      <Button onClick={handleSubmit} rounded='gradient w-full rounded-lg h-full'>
        {t({ id: 'apply' })}
      </Button>
    </div>
  )
}

export default Filters
