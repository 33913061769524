import React from 'react'
import Cookies from 'js-cookie'
import { IntlProvider } from 'react-intl'
import { useGlobal } from '../globalState'

import es from './es.json'
import en from './en.json'
import zh from './zh.json'
import ca from './ca.json'

const filterInvalidPathname = s => s && s !== '' && isValid(s)

export const defaultLang = 'en'
export const LANGS = ['es', 'en', 'zh', 'ca']
export const messages = { es, en, zh, ca }
export const COOKIE_KEY = 'locale'
export const isValid = lang => LANGS.includes(lang) ? lang : false
export const langFromUrl = () => window.location.pathname.split('/').filter(filterInvalidPathname).shift()

export const whichLang = () => {
  const locale = langFromUrl()

  if (locale) {
    Cookies.set(COOKIE_KEY, locale)
    return locale
  }

  const cookieLang = Cookies.get(COOKIE_KEY)
  if (cookieLang && isValid(cookieLang)) {
    return cookieLang
  }

  Cookies.set(COOKIE_KEY, defaultLang)
  return defaultLang
}

export const IntlProviderWrapper = ({ children }) => {
  const { state } = useGlobal()

  return (
    <IntlProvider locale={state.locale} messages={messages[state.locale]}>
      {children}
    </IntlProvider>
  )
}
