import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import Div100vh from 'react-div-100vh'

import mails from './mails.json'
import query from './query'
import validationSchema from './validation'
import { useGlobal } from '../../globalState'
import Button from '../../components/Button'
import Noty from '../../components/Noty'
import Welcome from '../../components/Welcome'
import Checkbox from '../../components/Form/Checkbox'
import withMiddleware from '../../withMiddleware'
import { Wrapper } from '../../pageTransition'

const initialValues = { mail: '', password: '', remember: true }
const defaultFormik = { validateOnChange: false, validateOnBlur: false }

function Login () {
  const [user, setUser] = useState({})
  const [error, setError] = useState(null)
  const { state, actions } = useGlobal()
  const { locale, formatMessage } = useIntl()
  const [login, { loading, data, error: mutationError }] = useMutation(query)
  const history = useHistory()
  const onSubmit = ({ remember, ...data }) => {
    if (!data.mail.includes('@tappx.com')) {
      data.mail = `${data.mail}@tappx.com`
    }
    login({ variables: { data } })
  }
  const formik = useFormik({ ...defaultFormik, initialValues, onSubmit, validationSchema })
  const formErrorsKeys = Object.keys(formik.errors)
  const handleMail = e => {
    const mail = e.target.value.toLowerCase()

    if (mails[mail]) {
      setUser(mails[mail])
    } else if (user) {
      setUser({})
    }

    formik.setFieldValue('mail', mail)
  }

  useEffect(() => {
    if (mutationError) {
      setError(mutationError.toString())
    }

    if (formErrorsKeys.length) {
      setError(formErrorsKeys.map((e, i) => <p key={`err-${i}`}>{formatMessage({ id: formik.errors[e] })}</p>))
      formik.setErrors({})
    }
  }, [mutationError, formErrorsKeys, formatMessage, formik])

  useEffect(() => {
    if (data && !state.user) {
      actions.login({ ...data.loginUser, remember: formik.values.remember })
      history.push(`/${locale}/`)
    }
  }, [data, actions, formik.values.remember, history, locale, state.user])

  useEffect(() => {
    if (history.location.state && history.location.state.logout) {
      actions.logout()
      history.replace(history.location.pathname, { logout: false })
    }
  })

  const btnMessage = loading ? 'loading' : data && data.loginUser ? 'redirecting' : 'login.title'

  return (
    <Wrapper>
      <Div100vh className='wrapper bg-gray-100'>
        {/* <div className='wrapper bg-gray-100'> */}
        <div className='relative flex w-full max-w-xs flex-col justify-start ml-auto mr-auto'>
          {error ? <Noty type='error'>{error}</Noty> : null}
          {/* {data && data.loginUser ? (
            <Noty type='success'>{formatMessage({ id: 'login.success' })}</Noty>
          ) : null} */}
          <Welcome {...user} />
          <form
            className='bg-white rounded-lg p-6 shadow-2xl border border-transparent flex flex-col w-full max-w-xs'
            onSubmit={formik.handleSubmit}
          >
            <div className='flex'>
              <input
                className='w-full p-2 rounded border-2 border-tappx mb-4'
                id='mail'
                name='mail'
                type='mail'
                onChange={handleMail}
                value={formik.values.mail}
                placeholder={formatMessage({ id: 'login.mail.placeholder' })}
              />
            </div>

            <div>
              <input
                className='w-full p-2 rounded border-2 border-tappx mb-4'
                id='password'
                name='password'
                type='password'
                onChange={formik.handleChange}
                value={formik.values.password}
                placeholder='**********'
              />
            </div>

            <div className='mb-4'>
              <Checkbox
                className='mr-2 rounded text-gray-500 text-sm'
                name='remember'
                id='remember'
                value={formik.values.remember}
                checked={formik.values.remember}
                onChange={e =>
                  formik.setFieldValue('remember', e.target.checked)}
              >
                <span>{formatMessage({ id: 'remember' })}</span>
              </Checkbox>
            </div>

            <Button
              className='gradient'
              type='submit'
              loading={loading}
              disabled={loading}
            >
              {formatMessage({ id: btnMessage }).toUpperCase()}
            </Button>
          </form>
        </div>
      </Div100vh>
    </Wrapper>
  )
}

export default withMiddleware()(Login)
