import React, { useEffect, useReducer, createContext, useContext, createRef } from 'react'
import { debounce } from 'throttle-debounce'

const LayoutContext = createContext()

const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
const TOGGLE_ACTIONS = 'TOGGLE_ACTIONS'
const ON_RESIZE = 'ON_RESIZE'
const isMobile = () => typeof window !== 'undefined' && window.innerWidth < 768

const initialState = {
  sidebarToggled: isMobile(),
  actionsToggled: false,
  viewport: {
    isMobile: isMobile(),
    width: window.innerWidth,
    height: window.innerHeight
  },
  refs: {
    wrap: createRef(null),
    sidebar: createRef(null),
    content: createRef(null),
    nav: createRef(null)
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_RESIZE:
      return {
        ...state,
        viewport: {
          ...state.viewport,
          isMobile: isMobile(),
          width: window.innerWidth,
          height: window.innerHeight
        }
      }
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarToggled: !state.sidebarToggled }
    case TOGGLE_ACTIONS:
      return { ...state, actionsToggled: !state.actionsToggled }
    default:
      return { ...state }
  }
}

export const LayoutProvider = ({ children }) => {
  return (
    <LayoutContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </LayoutContext.Provider>
  )
}

export const useLayout = () => {
  const [state, dispatch] = useContext(LayoutContext)

  const actions = {
    toggleSidebar: () => dispatch({ type: TOGGLE_SIDEBAR }),
    toggleActions: () => dispatch({ type: TOGGLE_ACTIONS })
  }

  useEffect(() => {
    const onResize = () => debounce(150, dispatch({ type: ON_RESIZE }))
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [dispatch])

  return { ...state, dispatch, actions }
}
