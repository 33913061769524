import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import gql from 'graphql-tag'
import { useIntl } from 'react-intl'

import { capitalize, snakeToCamel } from '../../lib/helpers'
import { useReportState, useReportDispatch, parseMetrics, parseDimensions, parseFilters } from './context'
import Datepicker from './Datepicker'
import Pagination from './Pagination'
import Table from '../Table'
import Filters from '../Filters'
import Share from './Share'
// import Graph from './Graph'
// import Segments from './Segments'
import Metrics from './Metrics'
import Dimensions from './Dimensions'
// import Bundle from './Bundle'
import Title from '../Title'
// import Toggle from '../Toggle'
import Button from '../Button'
import Noty from '../Noty'
import { ReactComponent as Loading } from '../../images/loading.svg'

const PAGE_SIZE = 50

const queryConfig = gql`
  query getDataviewerConfig($table: String!){
      dataviewerConfig (table: $table) {
          dimensions
          metrics
          filters
      }
  }
`

const query = (report, state) => {
  const q = `query getDataviewer ($table: String!, $params: JSON!, $export: Boolean) {
    dataviewer (
      table: $table,
      params: $params,
      export: $export,
    ) {
      pageSize
      currentPage
      totalRecords
      data
    }
  }`
  
  return gql`${q}`
}

function Report () {
  // const chartRef = useRef(null)
  // const history = useHistory()
  const { formatMessage: t } = useIntl()
  // const [skip, toggleSkip] = useState(true)
  const [reportError, setError] = useState(null)
  const { report } = useParams()
  const table = snakeToCamel(report)
  const state = useReportState()
  const { data: { fill }, setConfig, toggleLoading, toggleTotals, toggleSkip } = useReportDispatch()
  const { loading: loadingConfig, error, data: config, refetch } = useQuery(queryConfig, { fetchPolicy: 'no-cache', variables: { table } })
  const variables = {
    table,
    params: {
      currentPage: state.page,
      pageSize: PAGE_SIZE,
      sorting: state.sort,
      filters: state.filters && state.filters.length
        ? state.filters
          .filter(f => f.active && f.id !== 'date')
          .map(f => ({ [f.id]: f.value }))
        : [],
      data: [
        ...(state.dimensions && state.dimensions.length
          ? state.dimensions
            .filter(d => d.active)
            .map(d => d.id)
          : []),
        ...(state.metrics && state.metrics.length
          ? state.metrics
            .filter(m => m.active)
            .map(m => m.id)
          : [])
      ]
    },
    export: false,
  }  

  if (state.dates.startDate && state.dates.endDate) {
    variables.params.filters.push({ date: `${state.dates.startDate.format('YYYY-MM-DD')} - ${state.dates.endDate.format('YYYY-MM-DD')}` })
  }  

  const { loading: loadingQuery, error: errorQuery, data } = useQuery(query(report, state), { skip: state.skip, variables })

  function handleQuery (e) {
    e.preventDefault()
    if (!state.dates.startDate || !state.dates.endDate) {
      return setError('Dates are mandatory')
    }

    toggleSkip()
    toggleLoading()
  }

  useEffect(() => {
    if (data) {
      toggleSkip()      
      fill({
        data: data.dataviewer.data,
        pages: Math.ceil(data.dataviewer.totalRecords / data.dataviewer.pageSize)
      })
    }
  }, [data])

  useEffect(() => {
    if (config && !error) {
      const { metrics, filters, dimensions } = config.dataviewerConfig
      setConfig({
        metrics: parseMetrics(metrics),
        filters: parseFilters(filters),
        dimensions: parseDimensions(dimensions)
      })
    } else if (!config && error) {
      setError(error.message)
    }
  }, [error, config])
  
  // useEffect(() => {
  //   const listener = history.listen((location, action) => {
  //     if (report !== location.pathname.split('/').pop()) {
  //     }
  //   })

  //   return () => listener()
  // }, [history, report])  

  return (
    <div className='report'>
      {reportError ? <Noty type='error'>{reportError}</Noty> : null}
      <div className='report-head'>
        <Title>{capitalize(report).replace(/-/gi, ' ')}</Title>
        <div className='report-share'>
          <Share />
        </div>
      </div>

      {/* <div className="report-content flex-col md:flex-row">
        <Bundle />
        <Dimensions />
      </div> */}

      <div className='report-content'>
        <div className='w-full'>
          <div className='flex w-full'>
            <Datepicker />
          </div>
        </div>
        <div className='flex flex-shrink-0'>
          <div className='flex w-full justify-end'>
            <Metrics />
            <Dimensions />
            <div className='report-filters'>
              <Filters />
            </div>
            <Button className='gradient ml-4' rounded='rounded-lg' onClick={handleQuery}>
              {t({ id: 'search' })}
            </Button>
          </div>
        </div>
      </div>

      {/* <div className='report-content flex-col'>
        <div className='report-graph'>
          <div className='flex flex-row justify-start mb-4'>
            <Metrics />
            <Segments />
          </div>
          <Graph ref={chartRef} />
        </div>
      </div> */}

      {/* <div className='report-content flex-col'>
        <Toggle onChange={toggleTotals} checked={state.showTotals} />
      </div> */}

      {state.loading ? (
        <div className='w-full h-64 flex justify-center items-center'>
          <Loading />
        </div>
      ) : (
        <div className='report-content flex-col overflow-hidden -mx-4 md:-ml-0'>
          <Table />
          {state.pages ? <Pagination /> : null}
        </div>
      )}
    </div>
  )
}

export default Report
